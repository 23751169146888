























































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { UserGroupService } from '@/services/user-group-service';
import { I18nService } from '@cds/i18n';
import NovaPositionTransfer from '@/components/NovaGroupTransfer/NovaPositionTransfer.vue';
import ModifyUserGroup from '@/components/UserGroup/ModifyUserGroup.vue';
import { ElForm } from 'element-ui/types/form';
import { cloneDeep, isArray, isString, toLower } from 'lodash';
import { DivisionOrg, TYPESTATU } from '../index.interface';
import { DialogService } from '@/services/common/dialog.service';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { IAdminUser } from '@/views/manager';
import { GroupService } from '@/services/group-service';
import { divisionMap } from '../index.config';
import { uuid } from '@/services/utils';

@Component({
  components: { NovaPositionTransfer }
})
export default class ChannelRuleEdit extends Vue {
  public loading: boolean = false;
  public isShow: boolean = true;
  public hasPharmaPermission: boolean = false;
  public hasHrCorePermission: boolean = false;
  public hasNonFfPermission: boolean = false;
  public searchText: string = '';
  public data!: any;
  public showDropSearch: boolean = true; // 搜搜菜单
  public searchActivePramaList: any[] = [];
  public searchActiveOncoList: any[] = [];
  public searchActiveHRCoreList: any[] = [];
  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialog!: DialogService;
  //   @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(GroupService) private groupService!: GroupService;
  @Inject(CacheService) private cacheSvc!: CacheService;
  private applicationId: any;
  private filterText: any = '';
  private currentUser!: IAdminUser;
  private options: any[] = [];
  public totalGroupList: any[] = [];
  public checkedGroup: any[] = [];
  private userGroups: any[] = [];
  private filterGroupList: any[] = [];
  private isShowSearchResult: boolean = false;
  private passedUserGroups: any[] = [];
  private newGroup: any[] = [];
  private checkedUserList: any[] = [];
  private userGroupIds: any[] = [];
  private $dialogInstance!: any;

  private totalGroups: any = 0;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public hasPermission(item:any): Boolean {
    // const { dataPerm, id, name, type } = item;
    // if (dataPerm && isArray(dataPerm) && dataPerm.length > 0) {
    //   dataPerm.forEach((per: any) => {
    //     if (per == TYPESTATU.PHARMA && !this.hasPharmaPermission) {
    //       return false;
    //     }
    //     if (per == TYPESTATU.HRCORE && !this.hasHrCorePermission) {
    //       return false;
    //     }
    //   });
    // }
    // return true
    return this.totalGroupList.findIndex((userGroup: any) => item.id == userGroup.id) !== -1

  }


  private async getUserGroups(types: TYPESTATU): Promise<void> {}

  public async initAllUserGroups(): Promise<void> {
    try {
      console.log('created', this.data);
      await this.checkedGroupUsers(this.data);
      let permissionList = [];
      if (this.data.hasPharmaPermission) {
        permissionList.push(localStorage.getItem("setup"));
      }
      if (this.data.hasHrCorePermission) {
        permissionList.push('Hrcore');
      }
      if (this.data.hasNonFfPermission) {
        permissionList.push('Nonff');
      }

      let params = { dataPerm: permissionList.join(',') };
      let resultGroup = await this.userGroupService.getUserGroupsByPermission(params);
      this.totalGroupList = resultGroup
      if (this.data.userGroups) {
        const { userGroups } = this.data;
        userGroups.forEach((item: any) => {
          this.checkedGroup.push(item.id);
        });
      }
    } finally {
    }
  }

  // 创建规则
  private async createNewGroup(): Promise<void> {
    const key = uuid();
    this.$dialogInstance.hide();
    const res = await this.dialog.open(
      '创建应用权限规则',
      ModifyUserGroup,
      {
        disablePharmaTab: !this.data.hasPharmaPermission,
        disableHrcoreTab: !this.data.hasHrCorePermission,
        disableNonffTab: !this.data.hasNonFfPermission,
        key
      },
      {
        height: 'auto',
        key
      }
    );
    this.$dialogInstance.show();
    if (res.action === CloseStatus.confirm) {
      this.loading = true;
      await this.initAllUserGroups();
      if (res.result.formData) {
        const { id, name } = res.result.formData;
        this.checkedGroup.push(id);
        let hrcoreUsersList = (await this.groupService.batchGetUsersForHrCore([id])) || [];
        let effectiveUserList: any[] = [];
        if (hrcoreUsersList && isArray(hrcoreUsersList) && hrcoreUsersList.length == 1) {
          effectiveUserList = hrcoreUsersList[0].userVoList;
        }
        this.newGroup.push({
          id: id,
          name: name,
          type: 'hrCoreOrg',
          userList: effectiveUserList
        });
        this.loading = false;

      }
    }
  }

  // 页面初始化
  public async created(): Promise<void> {
    console.log('created',this.data)
    this.loading = true;
    await this.initAllUserGroups();
    this.loading = false;
  }

  //获取选中分组的用户列表
  public async checkedGroupUsers(data: any): Promise<void> {
    if (data.userGroups) {
      try {
        let parmaAndOncoUserIDList: any[] = [], hrcoreUserIDList: any[] = [];
        let newGroup = data.userGroups;

        for (const item of newGroup) {
          let res: any[] = [];

          if (item.type === DivisionOrg.HRCORE) {
            hrcoreUserIDList.push(item.id);
            // res = (await this.groupService.batchGetUsersForHrCore([item.id])) || [];
            // res =
            //   (await this.groupService.getUsersForHrCore({
            //     groupId: item.id
            //   })) || [];
          } else {
            parmaAndOncoUserIDList.push(item.id);
            // res = (await this.groupService.batchGetUsers([item.id])) || [];
            // res =
            //   (await this.groupService.getUsers({ groupId: item.id })) || [];
          }

          //   自定义item属性userList
          // item.userList = res[0].userVoList ? res[0].userVoList : []
          //   item.size = res.length;
        }
        let parmaAndOncoUserRes = await this.groupService.batchGetUsers(parmaAndOncoUserIDList);
        let hrcoreUserRes = await this.groupService.batchGetUsersForHrCore(hrcoreUserIDList);
        if (parmaAndOncoUserRes && isArray(parmaAndOncoUserRes) && parmaAndOncoUserRes.length > 0) {
          parmaAndOncoUserRes.forEach(parmaAndOncoUser =>{
            const { groupId, userVoList } = parmaAndOncoUser;
            for (const user of userVoList) {
              if (user.effectiveNumber === 0) {
                user.effectiveNumber = '-';
              }
            }
            let index = newGroup.findIndex((paramOrOnco: any) => groupId == paramOrOnco.id && paramOrOnco.type !== DivisionOrg.HRCORE);
            if (index !== -1 ){
              newGroup[index].userList = userVoList
            }
          })
        }

        if (hrcoreUserRes && isArray(hrcoreUserRes) && hrcoreUserRes.length > 0) {
          hrcoreUserRes.forEach(hrcoreUser =>{
            const { groupId, userVoList } = hrcoreUser;
            for (const user of userVoList) {
              if (user.effectiveNumber === 0) {
                user.effectiveNumber = '-';
              }
            }
            let index = newGroup.findIndex((hrcore:any) => groupId == hrcore.id && hrcore.type === DivisionOrg.HRCORE);
            if (index !== -1 ){
              newGroup[index].userList = userVoList
            }
          })
        }

        this.newGroup = newGroup;
      } finally {
      }
    }
  }

  //删除用户分组的用户列表项
  public deleteUserRecord(selectedItem: any) {
    console.log('selectedItem: ' + selectedItem);
    if (selectedItem) {
      if (this.newGroup.findIndex((item: any) => item.id === selectedItem) !== -1) {
        this.newGroup.splice(
          this.newGroup.findIndex((item: any) => item.id === selectedItem),
          1
        );
      }
      if (this.checkedGroup.findIndex((item: any) => item === selectedItem) !== -1) {
        this.checkedGroup.splice(
          this.checkedGroup.findIndex((item: any) => item === selectedItem),
          1
        );
      }
    }
  }

  //处理checkbox新增用户
  public async handleChangedClick(value: any) {
    console.log(value);
    let clickedGroupIdz = value.id;
    let clickedGroupName = value.name;
    let clickedGroupType = value.type;
    let clickedGroupId = this.newGroup.findIndex(
      (item: any) => item.id === value.id
    );

    //不存在
    if (clickedGroupId == -1) {
      let res: any[] = [];
      let checkedGroupUserList: any[] = [];
      if (value.type === DivisionOrg.HRCORE) {
        res = (await this.groupService.batchGetUsersForHrCore([value.id])) || [];

        // res =
        //   (await this.groupService.getUsersForHrCore({
        //     groupId: value.id
        //   })) || [];
      } else {
        res = (await this.groupService.batchGetUsers([value.id])) || [];

        // res =
        //   (await this.groupService.getUsers({ groupId: value.id })) ||
        //   [];
      }

      //  自定义item属性userList
      checkedGroupUserList = res[0].userVoList ? res[0].userVoList : [];
      console.log(checkedGroupUserList);
      //   item.size = res.length;
      // console.log('this.newGroup' + this.newGroup);
      this.newGroup.push({
        id: clickedGroupIdz,
        name: clickedGroupName,
        type: clickedGroupType,
        userList: checkedGroupUserList
      });
    } else {
      this.newGroup.splice(
        this.newGroup.findIndex((item: any) => item.id === value.id),
        1
      );
      this.newGroup.forEach((item: any) => {
        console.log(item);
      });
    }
  }

  public clearSearch(): void {
    if (!this.filterText) {
      this.isShowSearchResult = false;
    }
  }

  //模糊查询分组
  public search(): void {
    if (this.filterText) {
      this.filterRulesByName();
      this.isShowSearchResult = true;
    } else {
      this.isShowSearchResult = false;
    }
  }

  //按规则筛选名字
  public async filterRulesByName(): Promise<void> {
    let filteredGroupList: any[] = [];
    this.totalGroupList.forEach((item: any) => {
      let displayGroupList = cloneDeep(item);
      console.log('displayGroupList' + displayGroupList.name);
      //   console.log('item:' + item.name);
      if (
        displayGroupList.name &&
        isString(displayGroupList.name) &&
        toLower(displayGroupList.name).indexOf(
          toLower(this.filterText)
        ) >= 0
      ) {
        filteredGroupList.push(displayGroupList);
      }
    });
    this.filterGroupList = filteredGroupList;
  }

  // 已选中分组
  // private async getUserGroupsByApplication(id: any): Promise<void> {
  //   this.applicationId = id;
  //   let res = await this.groupService.getUserGroupsByApplication({
  //     applicationId: id
  //   });
  //   console.log(res);
  // }

  //确认按钮
  public async onSave() {
    console.log('this.newGroup', this.newGroup);
    this.loading = true;
    if (this.newGroup) {
      let userGroupIds: any[] = [];
      this.newGroup.forEach((item: any) => {
        if (item.id) {
          userGroupIds.push(item.id);
        }
      });
      // this.userGroupIds = userGroupIds;
      console.log('this.userGroupIds' + userGroupIds);
      let res = await this.groupService.saveUserGroupsByApplication({
        applicationId: this.data.applicationId,
        userGroupIds: userGroupIds
      });
      this.loading = false;
      this.dialog.close(CloseStatus.confirm);
    }
  }
  //取消按钮
  public cancelSave(): void {
    this.dialog.close(CloseStatus.close);
  }
}
